import * as THREE from 'three'
import React, { useRef, useState, useEffect } from 'react'
import { Canvas, useFrame, ThreeElements } from '@react-three/fiber'

type Velocity = {
	x: number
	y: number
	z: number
}

const settings = {
	particleSpeedFactor: 0.1,
	particleColor: '#0c647a',
	particleStartPos: {
		x: 1,
		y: 1,
		z: 1,
	},
	particleCount: 20,
}


const rand = () => Math.random() - 0.5

function Dot(props:ThreeElements['mesh']) {
	const ref = useRef<THREE.Mesh>(null!)
	const [velocity, setVelocity] = useState<Velocity>({ x:0, y:0, z:0 })

	useFrame((state, delta) => {

		setVelocity((velocity) => {
			const v = delta * settings.particleSpeedFactor
			velocity.x += rand() * v
			velocity.y += rand() * v
			velocity.z += rand() * v
			return velocity
		})

		ref.current.position.x += velocity.x
		ref.current.position.y += velocity.y
		ref.current.position.z += velocity.z
	})

	return (
		<mesh
			{...props}
			ref={ref}
			scale={1}
 			onPointerOver={() => {console.log("moved")}}
		>
			<sphereGeometry args={[0.1, 16, 8]} />
			<meshLambertMaterial color={settings.particleColor} transparent opacity={0.7} />
		</mesh>
	)
}

const mkId = () => Math.random().toString()
const mkIdArray = (n:number) => Array(n).fill(1).map(mkId)

export default
function Background() {
	const initPixels = mkIdArray(settings.particleCount)
	const [pixels, setPixels] = useState<string[]>(initPixels)

	function setTimer() {
		setTimeout(() => {
			setPixels((p) => {
				const toDel = Math.floor(Math.random() * p.length)
				p = p.filter((_, i) => i!==toDel)
				return p.concat(mkId())
			})
		}, 1000)
	}

	useEffect(() => {
		setTimer()
	})

	return (
		 <Canvas >
		   <ambientLight intensity={Math.PI / 2} />
		   <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} decay={0} intensity={Math.PI} />
		   <pointLight position={[-10, -10, -10]} decay={0} intensity={Math.PI} />
		   {
				pixels.map((pixel) => <Dot key={pixel} position={[
					settings.particleStartPos.x,
					settings.particleStartPos.y,
					settings.particleStartPos.z,
				]}/>)
		   }
		 </Canvas>
	)
}
