import { Nav } from "react-bootstrap"

export default Nav.Link

//function NLink(props:NavLinkProps) {
//	return (
//		<Nav.Link {...props} />
//	)
//}

