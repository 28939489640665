import { MdMail } from "react-icons/md"
import TwitterIcon from "./assets/icons/twitter.png"
import GithubIcon from "./assets/icons/github.png"
import { Image } from "react-bootstrap"
import NLink from "./NLink"

function emailAddress() {
	const charCodes = [119, 101, 98, 115, 105, 116, 101, 64, 111, 109, 97, 110, 111, 109, 46, 99, 111, 109]

	let str = ""
	charCodes.map((code) => str += String.fromCharCode(code))

	return str
}

export
function Github() {
	return <NLink href="https://github.com/byronmurg/" ><Image src={GithubIcon} height="32px" /></NLink>
}

export
function Twitter() {
	return <NLink href="https://twitter.com/ByronMurgatroyd/" ><Image src={TwitterIcon} height="32px" /></NLink>
}

export
function Email() {
	const address = emailAddress()
	return <NLink href={`mailto:${address}`} ><MdMail style={{"fontSize":"32px"}} /></NLink>
}

